import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import particlesConfig from '../config/particlesjs-config.json';

function Home() {
  useEffect(() => {
    document.title = "Genhtis";
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js';
    script.async = true;
    script.onload = () => {
      window.particlesJS('particles-js', particlesConfig);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleEmailClick = () => {
    window.location.href = 'mailto:arthur.koerner@genhtis.com';
  };

  const handleButtonMouseEnter = () => {
    if (window.pJSDom && window.pJSDom[0].pJS) {
      window.pJSDom[0].pJS.particles.move.speed = 8;
    }
  };

  const handleButtonMouseLeave = () => {
    if (window.pJSDom && window.pJSDom[0].pJS) {
      window.pJSDom[0].pJS.particles.move.speed = 2;
    }
  };

  return (
    <div className="App">
      <div id="particles-js"></div>
      <div className="content">
        <div className="text-container">
          <h1>Genhtis</h1>
          <p className="tagline">Revolutionizing Global Heparin Production</p>
        </div>
        <button 
          onClick={handleEmailClick}
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleButtonMouseLeave}
        >
          Contact Us
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default Home;