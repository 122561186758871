import React from 'react';
import { Link } from 'react-router-dom';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';
import './Pages.css';

function PrivacyPolicy() {
  return (
    <div className="page-container">
      <div className="page-content">
        <PrivacyPolicyContent />
        <Link to="/" className="back-link">Back to Home</Link>
      </div>
    </div>
  );
}

export default PrivacyPolicy;