import React from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';

function Imprint() {
  return (
    <div className="imprint-container">
      <div className="imprint-content">
        <h1>Imprint</h1>
        
        <div className="imprint-details">
          <p>Genhtis Fine Chemicals GmbH</p>
          <p>Strasse des Friedens 13</p>
          <p>04769 Mügeln</p>
        </div>

        <div className="imprint-details">
          <p>Responsible for content in accordance with §55 paragraph 2 RStV:</p>
          <p>Arthur Koerner</p>
          <p><a href="mailto:arthur.koerner@genhtis.com">arthur.koerner@genhtis.com</a></p>
        </div>

        <div className="imprint-details">
          <p>Trade Register Number: HRB 39468</p>
          <p>Register Court: Amtsgericht Leipzig</p>
          <p>VAT: DE34 8964 316</p>
        </div>

        <Link to="/" className="back-link">Back to Home</Link>
      </div>
    </div>
  );
}

export default Imprint;